import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Row, Col, FormGroup } from 'react-bootstrap'
import Swal from 'sweetalert2'
import Select from '../../../utils/Form/Widgets/Select'
import { DateRangeInput } from '../../../utils/Form/Widgets/DateRangeInput'
import { useAuth } from '../../auth/core/AuthProvider'
import { TaskCommentEditModal } from './TaskCommentEditModal'

interface TaskComment {
  ID: number
  task: number
  user: number
  body: string
  created_at: string
  last_updated: string
}

interface FilterParams {
  task?: string
  user?: string
  created_at_start?: string
  created_at_end?: string
  last_updated_start?: string
  last_updated_end?: string
  page: number
  limit: number
}

const CompanyTaskComments = ({ companyId }: { companyId: number }) => {
  const { user } = useAuth()
  const [taskComments, setTaskComments] = useState<TaskComment[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [itemIdForComment, setItemIdForComment] = useState('')

  const [filters, setFilters] = useState<FilterParams>({
    page: 1,
    limit: 50,
  })
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  const getTasks = async () => {
    try {
      setLoading(true)
      const queryParams = new URLSearchParams()

      Object.entries(filters).forEach(([key, value]) => {
        if (value) queryParams.append(key, value.toString())
      })

      const response = await axios.get(
        `/task_comments/getCommentsByCompany/${companyId}?${queryParams}`
      )
      if (response.data.data) {
        setTaskComments(response.data.data.data)
        setTotalPages(Math.ceil(response.data.data.pagination.total / filters.limit))
      }
    } catch (error) {
      console.error('Error fetching tasks:', error)
    } finally {
      setLoading(false)
    }
  }

  const [taskOptions, setTaskOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])

  const loadSelectOptions = async () => {
    try {
      const [taskResponse, userResponse] = await Promise.all([
        axios.get('/tasks/getTaskOptions/' + companyId),
        axios.get('/users/getUserOptions/all'),
      ])
      setTaskOptions(taskResponse.data.data)
      setUserOptions(userResponse.data.data)
    } catch (error) {
      console.error('Error loading select options:', error)
    }
  }

  useEffect(() => {
    loadSelectOptions()
  }, [])

  useEffect(() => {
    getTasks()
  }, [filters, companyId])

  const handleFilterChange = (field: keyof FilterParams, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
      page: 1,
    }))
  }
  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  const handleRefreshNotes = () => {
    getTasks()
    setItemIdForUpdate('')
  }

  const handleDelete = async (ID: number): Promise<void> => {
    const result = await Swal.fire({
      title: 'Warning',
      html: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      try {
        await axios.delete(`/task_comments/save/${ID}`)
        setTaskComments((prevTasks) => prevTasks.filter((task) => task.ID !== ID))
        Swal.fire('Deleted!', 'Task has been deleted successfully.', 'success')
      } catch (error) {
        console.error('Delete error:', error)
        Swal.fire('Error!', 'Failed to delete task.', 'error')
      }
    }
  }

  return (
    <div className='card'>
      {/* Filtre Bölümü */}
      <div className='card-header border-0 pt-5'>
        <Row className='align-items-end'>
          <Col md={2}>
            <FormGroup>
              <Select
                title='Task'
                name='task'
                options={taskOptions || []}
                onChange={(event: any) => handleFilterChange('task', event.target.value)}
                defaultValue={filters?.task || ''}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Select
                title='User'
                name='user'
                options={userOptions || []}
                onChange={(event: any) => handleFilterChange('user', event.target.value)}
                defaultValue={filters?.user || ''}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <DateRangeInput
                title='Created Date Range'
                onChange={(dates: any) => {
                  const [start, end] = dates.target.value.split(' to ')
                  handleFilterChange('created_at_start', start)
                  handleFilterChange('created_at_end', end)
                }}
                name='created_at'
                seperator={'to'}
              />
            </FormGroup>
          </Col>

          <Col md={4} style={{ marginLeft: 'auto' }}>
            <div className='d-flex justify-content-end gap-2 mb-1'>
              <Button
                variant='secondary'
                onClick={() => setFilters({ page: 1, limit: 50 })}
                className='w-50'
              >
                <i className='fas fa-redo'></i> Reset
              </Button>

              <Button variant='primary' onClick={() => handleAdd()} className='w-50'>
                <i className='fas fa-plus'></i> Add Comment
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {/* Tablo Bölümü */}
      <div className='card-body py-3'>
        {loading ? (
          <div className='text-center py-10'>
            <h3 className='text-muted'>Loading...</h3>
          </div>
        ) : taskComments.length > 0 ? (
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Actions</th>
                  <th>Task</th>
                  <th>User</th>
                  <th>Body</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {taskComments &&
                  taskComments.length > 0 &&
                  taskComments.map((comment) => (
                    <tr key={comment.ID}>
                      <td>
                        <div className='d-flex justify-content flex-shrink-0'>
                          <button
                            onClick={() => handleView(comment.ID)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='fa fa-edit'></span>
                          </button>
                          <button
                            onClick={() => handleDelete(comment.ID)}
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          >
                            <span className='fa fa-trash'></span>
                          </button>
                        </div>
                      </td>
                      <td>{comment.task}</td>
                      <td>{comment.user}</td>
                      <td>{comment.body}</td>
                      <td>{comment.created_at}</td>
                      <td>{comment.last_updated}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center py-10'>
            <h3 className='text-muted'>No tasks comments available.</h3>
          </div>
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className='card-footer d-flex justify-content-center'>
          <div className='d-flex gap-2'>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <Button
                key={page}
                variant={filters.page === page ? 'primary' : 'outline-primary'}
                onClick={() => handleFilterChange('page', page)}
                size='sm'
              >
                {page}
              </Button>
            ))}
          </div>
        </div>
      )}
      {itemIdForUpdate !== '' && (
        <TaskCommentEditModal
          show={true}
          itemIdForUpdate={itemIdForUpdate}
          taskId={itemIdForComment}
          userId={user?.userId}
          onHide={() => {
            handleRefreshNotes()
          }}
          refreshFunction={handleRefreshNotes}
          companyId={companyId}
        />
      )}
    </div>
  )
}

export default CompanyTaskComments
