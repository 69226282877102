import { ReactNode, SyntheticEvent, useState } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler, { FormParams } from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import Select from '../../utils/Form/Widgets/Select'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'
import Check from '../../utils/Form/Widgets/Check'
import Textarea from '../../utils/Form/Widgets/Textarea'
import { companySitesList } from '../charts/core/_requests'
import EntityFinder from '../../utils/Form/Widgets/EntityFinder'
import { CreateEnergyTypeModal } from './components/CreateEnergyTypeModal'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
  companyId?: string
}

class Meter {
  company?: number
  site?: number
  code?: string
  meter_type?: string
  energy_scope?: string
  notes?: string
  is_active?: number
}

class ModalState {
  siteOptions?: {
    value: string
    label: string
  }[]
  isEnergyTypeListModalOpen?: boolean
}

class MetersEditModal extends FormHandler {
  isActiveOptions = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },
  ]
  meterTypes = [
    {
      value: 'Electricity',
      label: 'Electricity',
    },
    {
      value: 'Gas',
      label: 'Gas',
    },
    {
      value: 'Water',
      label: 'Water',
    },
  ]

  energyScopeOptions = [
    {
      value: 'N/A',
      label: 'N/A',
    },
    {
      value: 'Scope 1',
      label: 'Scope 1',
    },
    {
      value: 'Scope 2',
      label: 'Scope 2',
    },
    {
      value: 'Scope 3',
      label: 'Scope 3',
    },
  ]

  public state: FormParams & ModalState
  constructor(props: Props) {
    super(props)
    const initialEntity = new Meter()
    if (props.itemIdForUpdate === 'add' && props.companyId) {
      initialEntity.company = Number(props.companyId)
    }
    this.state = { entity: initialEntity, siteOptions: [] }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any): void {
    let state = this.state as any
    let prev = prevState as any
    if (prev.entity.company !== state.entity.company) {
      this.getSites()
    }
  }

  getSites() {
    let state = this.state as any
    if (state.entity.company) {
      companySitesList({ companyId: Number(state.entity.company) }).then((response) => {
        this.setState({
          ...this.state,
          siteOptions: response,
        })
      })
    }
  }

  getApiUrl(): string {
    return '/company/meters/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/company/meters/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      meters: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Meter'
    let disabledCompany = false
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = entity.name
    }
    let defaultCompanyValue = state.entity?.company || 0
    if (this.props.itemIdForUpdate !== 'add') {
      title = 'Edit Meter ID:' + this.props.itemIdForUpdate
      disabledCompany = true
    } else if (this.props.companyId) {
      disabledCompany = true
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
                isDisabled={disabledCompany}
              />
            </FormGroup>
            <FormGroup>
              <Select
                defaultValue={state.entity.site}
                name='site'
                title='Site'
                onChange={(event) => {
                  this.handleInputChange(event)
                }}
                options={state.siteOptions}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Meter Code'
                className='form-control'
                type='text'
                name='code'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.code}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Meter Type'
                name='meter_type'
                options={this.meterTypes}
                defaultValue={state.entity.meter_type}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>

            <FormGroup>
              <EntityFinder
                title='Energy Types'
                name='energy_subtype'
                type='select'
                placeholder='Select Energy Types'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.energy_subtype}
                buttonClass='btn btn-primary btn-sm'
                dataUrl='/energy_types/listCombine'
                singleUrl='/energy_subtypes/getValue'
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Energy Scope'
                name='energy_scope'
                options={this.energyScopeOptions}
                defaultValue={state.entity.energy_scope}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Check
                title='Is Active'
                name='is_active'
                type='switch'
                checked={state.entity.is_active ? true : false}
                options={['']}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { MetersEditModal }
