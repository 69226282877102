import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Badge, Form, Row, Col, FormGroup } from 'react-bootstrap'
import { TaskEditModal } from '../../company_tasks/TaskEditModal'
import Swal from 'sweetalert2'
import TaskCommentModal from '../../company_tasks/TaskCommentModal'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'
import Select from '../../../utils/Form/Widgets/Select'
import { DateRangeInput } from '../../../utils/Form/Widgets/DateRangeInput'

interface Task {
  ID: number
  company: number
  subject: string
  action: string
  status: 'in_progress' | 'pending' | 'completed' | 'closed' | 'outstanding'
  due_date: string
  responsible: number
  category: string
  created_at: string
  last_updated: string
}

interface FilterParams {
  responsible?: string
  subject?: string
  category?: string
  status?: string
  created_at_start?: string
  created_at_end?: string
  last_updated_start?: string
  last_updated_end?: string
  due_date_start?: string
  due_date_end?: string
  page: number
  limit: number
}

const CompanyTasks = ({ companyId }: { companyId: number }) => {
  const [tasks, setTasks] = useState<Task[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [itemIdForComment, setItemIdForComment] = useState('')

  const [commentsCount, setCommentsCount] = useState<
    Array<{
      task: number
      comment_count: number
    }>
  >([])

  const [filters, setFilters] = useState<FilterParams>({
    page: 1,
    limit: 50,
  })
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  const getTasks = async () => {
    try {
      setLoading(true)
      const queryParams = new URLSearchParams()

      Object.entries(filters).forEach(([key, value]) => {
        if (value) queryParams.append(key, value.toString())
      })

      const response = await axios.get(`/tasks/getTasksByCompany/${companyId}?${queryParams}`)
      if (response.data.data) {
        setTasks(response.data.data.data)
        setTotalPages(Math.ceil(response.data.data.pagination.total / filters.limit))
      }
    } catch (error) {
      console.error('Error fetching tasks:', error)
    } finally {
      setLoading(false)
    }
  }

  const [userOptions, setUserOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subjectOptions, setSubjectOptions] = useState([])

  const loadSelectOptions = async () => {
    try {
      const [userResponse, categoryResponse, subjectResponse, commentCountResponse] =
        await Promise.all([
          axios.get('/users/getUserOptions/all'),
          axios.get('/task_categories/getTaskCategoryOptions'),
          axios.get('/task_subjects/getTaskSubjectOptions'),
          axios.get('/tasks/getTaskCommentCount'),
        ])
      setUserOptions(userResponse.data.data)
      setCategoryOptions(categoryResponse.data.data)
      setSubjectOptions(subjectResponse.data.data)
      setCommentsCount(commentCountResponse.data.data)
    } catch (error) {
      console.error('Error loading select options:', error)
    }
  }

  useEffect(() => {
    loadSelectOptions()
  }, [])

  useEffect(() => {
    getTasks()
  }, [filters, companyId])

  const handleFilterChange = (field: keyof FilterParams, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
      page: 1,
    }))
  }

  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'in_progress':
        return <Badge bg='primary'>In Progress</Badge>
      case 'pending':
        return <Badge bg='warning'>Pending</Badge>
      case 'completed':
        return <Badge bg='success'>Completed</Badge>
      case 'closed':
        return <Badge bg='secondary'>Closed</Badge>
      case 'outstanding':
        return <Badge bg='danger'>Outstanding</Badge>
      default:
        return <Badge bg='dark'>{status}</Badge>
    }
  }

  const handleRefreshNotes = () => {
    getTasks()
    setItemIdForUpdate('')
  }

  const handleDelete = async (ID: number): Promise<void> => {
    const result = await Swal.fire({
      title: 'Warning',
      html: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })

    if (result.isConfirmed) {
      try {
        await axios.delete(`/tasks/save/${ID}`)
        setTasks((prevTasks) => prevTasks.filter((task) => task.ID !== ID))
        Swal.fire('Deleted!', 'Task has been deleted successfully.', 'success')
      } catch (error) {
        console.error('Delete error:', error)
        Swal.fire('Error!', 'Failed to delete task.', 'error')
      }
    }
  }

  const handleComment = (ID: number): void => {
    setItemIdForComment(ID.toString())
  }

  return (
    <div className='card'>
      {/* Filtre Bölümü */}
      <div className='card-header border-0 pt-5'>
        <Row className='align-items-end'>
          <Col md={2}>
            <FormGroup>
              <Select
                title='Responsible'
                name='responsible'
                options={userOptions}
                onChange={(event: any) => handleFilterChange('responsible', event.target.value)}
                defaultValue={filters?.responsible || ''}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Select
                title='Subject'
                name='subject'
                options={
                  subjectOptions && subjectOptions.length > 0
                    ? subjectOptions
                    : [
                        {
                          value: 'NavigateToAddSubject',
                          label: 'Please Add Subject From Settings. Click for Settings!',
                        },
                      ]
                }
                onChange={(event: any) => {
                  if (event.target.value === 'NavigateToAddSubject') {
                    window.location.href = '/settings/task_subjects'
                  }
                  handleFilterChange('subject', event.target.value)
                }}
                defaultValue={filters?.subject || ''}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Select
                title='Category'
                name='category'
                options={
                  categoryOptions && categoryOptions.length > 0
                    ? categoryOptions
                    : [
                        {
                          value: 'NavigateToAddCategory',
                          label: 'Please Add Category From Settings. Click for Settings!',
                        },
                      ]
                }
                onChange={(event: any) => {
                  if (event.target.value === 'NavigateToAddCategory') {
                    window.location.href = '/settings/task_categories'
                  }
                  handleFilterChange('category', event.target.value)
                }}
                defaultValue={filters?.category || ''}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <DateRangeInput
                title='Created Date Range'
                onChange={(dates: any) => {
                  const [start, end] = dates.target.value.split(' to ')
                  handleFilterChange('created_at_start', start)
                  handleFilterChange('created_at_end', end)
                }}
                name='created_at'
                seperator={'to'}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <DateRangeInput
                title='Due Date Range'
                onChange={(dates: any) => {
                  const [start, end] = dates.target.value.split(' to ')
                  handleFilterChange('due_date_start', start)
                  handleFilterChange('due_date_end', end)
                }}
                name='due_date'
                seperator={'to'}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className='form-label'>Status</label>
              <div className='d-flex flex-wrap gap-2'>
                {[
                  { value: 'in_progress', label: 'In Progress', variant: 'primary' },
                  { value: 'pending', label: 'Pending', variant: 'warning' },
                  { value: 'completed', label: 'Completed', variant: 'success' },
                  { value: 'closed', label: 'Closed', variant: 'secondary' },
                  { value: 'outstanding', label: 'Outstanding', variant: 'danger' },
                ].map((status) => (
                  <Button
                    key={status.value}
                    variant={
                      filters.status?.includes(status.value)
                        ? status.variant
                        : `outline-${status.variant}`
                    }
                    size='sm'
                    style={{ borderWidth: '1px', borderStyle: 'dashed' }}
                    onClick={() => {
                      const currentStatuses = filters.status?.split(',').filter(Boolean) || []
                      const newStatuses = currentStatuses.includes(status.value)
                        ? currentStatuses.filter((s) => s !== status.value)
                        : [...currentStatuses, status.value]
                      handleFilterChange('status', newStatuses.join(','))
                    }}
                  >
                    {status.label}
                  </Button>
                ))}
              </div>
            </FormGroup>
          </Col>

          <Col md={4} style={{ marginLeft: 'auto' }}>
            <div className='d-flex justify-content-end gap-2 mb-1 mt-2'>
              <Button
                variant='secondary'
                onClick={() => setFilters({ page: 1, limit: 50 })}
                className='w-50'
              >
                <i className='fas fa-redo'></i> Reset
              </Button>

              <Button variant='primary' onClick={() => handleAdd()} className='w-50'>
                <i className='fas fa-plus'></i> Add Task
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {/* Tablo Bölümü */}
      <div className='card-body py-3'>
        {loading ? (
          <div className='text-center py-10'>
            <h3 className='text-muted'>Loading...</h3>
          </div>
        ) : tasks.length > 0 ? (
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Actions</th>
                  <th>Responsible</th>
                  <th>Subject</th>
                  <th>Action</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                  <th>Due Date</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) => (
                  <tr key={task.ID}>
                    <td style={{ width: '200px' }}>
                      <div className='d-flex justify-content flex-shrink-0'>
                        <button
                          onClick={() => handleView(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-25'
                        >
                          TASK_{task.ID}
                        </button>
                        <button
                          onClick={() => handleView(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <span className='fa fa-edit'></span>
                        </button>
                        <button
                          onClick={() => handleDelete(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        >
                          <span className='fa fa-trash'></span>
                        </button>
                        <button
                          onClick={() => handleComment(task.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                          style={{ position: 'relative' }}
                        >
                          <span className='fa fa-comment'></span>
                          {commentsCount &&
                            (commentsCount.find((item) => item.task === task.ID)?.comment_count ??
                              0) > 0 && (
                              <span
                                className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'
                                style={{ fontSize: '0.8em' }}
                              >
                                {commentsCount.find((item) => item.task === task.ID)?.comment_count}
                              </span>
                            )}
                        </button>
                      </div>
                    </td>
                    <td>{task.responsible}</td>
                    <td>{task.subject}</td>
                    <td>{task.action}</td>
                    <td>{task.category}</td>
                    <td>{getStatusBadge(task.status)}</td>
                    <td style={{ width: '125px' }}>{task.created_at}</td>
                    <td style={{ width: '125px' }}>{task.last_updated}</td>
                    <td style={{ width: '125px' }}>{task.due_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center py-10'>
            <h3 className='text-muted'>No tasks available.</h3>
          </div>
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className='card-footer d-flex justify-content-center'>
          <div className='d-flex gap-2'>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <Button
                key={page}
                variant={filters.page === page ? 'primary' : 'outline-primary'}
                onClick={() => handleFilterChange('page', page)}
                size='sm'
              >
                {page}
              </Button>
            ))}
          </div>
        </div>
      )}

      {/* Modals */}
      {itemIdForUpdate !== '' && (
        <TaskEditModal
          show={true}
          itemIdForUpdate={itemIdForUpdate}
          onHide={handleRefreshNotes}
          companyId={companyId}
        />
      )}
      {itemIdForComment !== '' && (
        <TaskCommentModal
          show={itemIdForComment !== ''}
          itemId={itemIdForComment}
          onHide={() => setItemIdForComment('')}
        />
      )}
    </div>
  )
}

export default CompanyTasks
