import { FC, SyntheticEvent, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import Table from '../../../utils/ResultViewer/Table'
import { useIntl } from 'react-intl'
import { TaskCategoriesEditModal } from './TaskCategoriesEditModal'
const TaskCategoriesList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const intl = useIntl()
  return (
    <>
      <Table
        title={intl.formatMessage({ id: 'MENU.SETTINGS.TASKCATEGORIES' })}
        dataUrl='/task_categories/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/task_categories/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you want to delete this task category?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('task_categories/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh()
                  })
              }
            })
          })
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'SETTINGS.TASKS.ADDCATEGORY' }),
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-plus',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate('add')
              setRefreshFunction(() => refresh)
            },
          },
        ]}
      />
      {itemIdForUpdate !== '' ? (
        <TaskCategoriesEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export { TaskCategoriesList }
