import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { Modal, Button, Badge } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import { CommentEditModal } from './CommentEditModal'
import { useAuth } from '../auth/core/AuthProvider'

interface CompanyNotesModalProps {
  show: boolean
  itemId: string
  onHide: () => void
  companyId?: string
}

interface Comment {
  ID: number
  task: string
  user: string
  body: string
  created_at: string
  last_updated: string
}

const TaskCommentModal: FC<CompanyNotesModalProps> = ({ show, itemId, onHide, companyId }) => {
  const navigate = useNavigate()
  const [comments, setComments] = useState<Comment[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const { user } = useAuth()
  const getComments = () => {
    axios.get('/task_comments/getComments/' + itemId).then((response) => {
      if (response.data.data) {
        setComments(response.data.data)
      }
    })
  }

  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  useEffect(() => {
    if (show) {
      getComments()
    }
  }, [show])

  const handleRefreshComments = () => {
    getComments()
    setItemIdForUpdate('')
  }

  const backdropStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1060,
  }

  const nestedModalStyle: React.CSSProperties = {
    position: 'relative',
    zIndex: 1065,
  }

  const nestedModalContentStyle: React.CSSProperties = {
    boxShadow: '0 0 20px rgba(0,0,0,0.3)',
    border: '2px solid #dee2e6',
    borderRadius: '8px',
    backgroundColor: 'white',
  }

  return (
    <>
      <Modal show={show} onHide={onHide} size='lg' style={{ zIndex: 1050 }}>
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {comments.length > 0 ? (
            <table className='table table-striped table-hover'>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>User</th>
                  <th>Comment</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {comments.map((comment) => (
                  <tr key={comment.ID}>
                    <td>
                      <Button
                        variant='primary'
                        size='sm'
                        style={{ marginLeft: '5px', padding: '2px 5px', fontSize: '12px' }}
                        onClick={() => handleView(comment.ID)}
                      >
                        View
                      </Button>
                    </td>
                    <td>{comment.user}</td>
                    <td>
                      {comment.body.length > 50
                        ? comment.body.substring(0, 50) + '...'
                        : comment.body}
                    </td>

                    <td>{comment.created_at}</td>
                    <td>{comment.last_updated}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No comments available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => handleAdd()}>
            Add Comment
          </Button>
          <Button variant='secondary' onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {itemIdForUpdate !== '' && (
        <>
          <div style={backdropStyle} />

          <div style={nestedModalStyle}>
            <CommentEditModal
              show={true}
              itemIdForUpdate={itemIdForUpdate}
              taskId={itemId}
              userId={user?.userId}
              onHide={() => {
                handleRefreshComments()
              }}
              dialogClassName='modal-dialog'
              contentStyle={nestedModalContentStyle}
            />
          </div>
        </>
      )}
    </>
  )
}

export default TaskCommentModal
