/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'
import { FormGroup } from 'react-bootstrap'
import axios from 'axios'
import { CompanyDetails } from './CompanyDetails'
import CompanyNotes from './CompanyNotes'
import CompanyTasks from './CompanyTasks'
import CompanyTaskComments from './CompanyTaskComments'
import CompanySites from './CompanySites'
import CompanyMeters from './CompanyMeters'
export interface CompanyAddress {
  company: number
  company_token: string
  address: string
  address_token: string
  phone: string
  email: string
  city: string
  county: string
  country: number
  postcode: string
  location: string
}

export interface Company {
  name: string
  token: string
  logo: number
  sole_trader: number
  fiscal_day: number
  fiscal_month: number
  number_of_empleyees: number
  status: string
  is_reseller: number
  company_token: string | null
  target_consumption_ratio: number
  company_addresses: CompanyAddress[]
}

const CompanyHeader: React.FC = () => {
  const { id } = useParams()
  const [company, setCompany] = useState<any>({ target: { value: id } })
  const [companyDetails, setCompanyDetails] = useState<any>(null)
  let [logoUrl, setLogoUrl] = useState<any>(null)
  let [activeTab, setActiveTab] = useState<string>('details')

  const handleCompanyChange = (selectedCompany: any) => {
    setCompany(selectedCompany)
  }

  const fetchCompanyDetails = async (companyId: number): Promise<{ data: Company }> => {
    const response = await axios.get(`/company/single/${companyId}`)
    return response.data
  }

  const fetchLogoFileUrl = async (fileId: Number) => {
    try {
      const response = await axios.get(`/files/getFileData/${fileId}`)
      return (response.data.data.file_path + '/' + response.data.data.file_name) as string
    } catch (error) {
      console.error('Error fetching logo file URL:', error)
      return '/public/media/avatars/blank.png'
    }
  }

  useEffect(() => {
    if (company.target.value) {
      fetchCompanyDetails(company.target.value).then((data) => {
        /*
        if (data.data.logo) {
          fetchLogoFileUrl(data.data.logo).then((logoUrl) => {
            setLogoUrl(logoUrl)
          })
        }*/
        setCompanyDetails(data.data)
      })
      setActiveTab('details')
    } else {
      setCompanyDetails(null)
    }
  }, [company])

  logoUrl = toAbsoluteUrl('/media/avatars/blank.png')

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={logoUrl} alt='energy_impact' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <FormGroup className='mb-0' style={{ width: '250px' }}>
                      <AutocompleteSelect
                        title='Company'
                        name='company'
                        onChange={handleCompanyChange}
                        defaultValue={company.target.value || 0}
                        autoCompleteTokenUrl='/company/autocomplete'
                      />
                    </FormGroup>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <div className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      {companyDetails?.company_addresses[0].address.substring(0, 100)}
                    </div>
                    <a
                      href={`tel:${companyDetails?.company_addresses[0].phone}`}
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen049.svg'
                        className='svg-icon-4 me-1'
                      />
                      {companyDetails?.company_addresses[0].phone}
                    </a>
                    <a
                      href={`mailto:${companyDetails?.company_addresses[0].email}`}
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {companyDetails?.company_addresses[0].email}
                    </a>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/maps/map001.svg'
                          className='svg-icon-3 me-2'
                        />
                        <div className='fs-2 fw-bolder'>{'N/A'}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Sites</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen019.svg'
                          className='svg-icon-3 me-2'
                        />
                        <div className='fs-2 fw-bolder'>{'N/A'}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Meters</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/communication/com013.svg'
                          className='svg-icon-3 me-2'
                        />
                        <div className='fs-2 fw-bolder'>{companyDetails?.number_of_empleyees}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Employees</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` + (activeTab === 'details' && 'active')
                  }
                  to={`details/` + company.target.value}
                  onClick={() => {
                    setActiveTab('details')
                  }}
                >
                  Details
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` + (activeTab === 'sites' && 'active')
                  }
                  to={`sites/` + company.target.value}
                  onClick={() => {
                    setActiveTab('sites')
                  }}
                >
                  Sites
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` + (activeTab === 'meters' && 'active')
                  }
                  to={`meters/` + company.target.value}
                  onClick={() => {
                    setActiveTab('meters')
                  }}
                >
                  Meters
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` + (activeTab === 'notes' && 'active')
                  }
                  to={`notes/` + company.target.value}
                  onClick={() => {
                    setActiveTab('notes')
                  }}
                >
                  Notes
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` + (activeTab === 'tasks' && 'active')
                  }
                  to={`tasks/` + company.target.value}
                  onClick={() => {
                    setActiveTab('tasks')
                  }}
                >
                  Tasks
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (activeTab === 'task_comments' && 'active')
                  }
                  to={`task_comments/` + company.target.value}
                  onClick={() => {
                    setActiveTab('task_comments')
                  }}
                >
                  Task Comments
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {company?.target.value && activeTab === 'details' && (
        <CompanyDetails company={companyDetails} />
      )}

      {company?.target.value && activeTab === 'sites' && (
        <CompanySites companyId={company.target.value} />
      )}
      {company?.target.value && activeTab === 'meters' && (
        <CompanyMeters companyId={company.target.value} />
      )}
      {company?.target.value && activeTab === 'notes' && (
        <CompanyNotes companyId={company.target.value} />
      )}

      {company?.target.value && activeTab === 'tasks' && (
        <CompanyTasks companyId={company.target.value} />
      )}
      {company?.target.value && activeTab === 'task_comments' && (
        <CompanyTaskComments companyId={company.target.value} />
      )}
    </>
  )
}

export { CompanyHeader }
