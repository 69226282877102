import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import CalendarViewPage from './calendar/CalendarViewPage'
import AnalyticsPage from '../analytics/AnalyticsPage'
import { useIntl } from 'react-intl'
import { ReportsList } from './reports/ReportsList'
import { TemplatesList } from './reports/TemplatesList'
import TemplateEdit from './reports/TemplateEdit'
import TemplateReportEdit from './reports/TemplateReportEdit'
import PredictionPage from '../analytics/PredictionPage'

const reportsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/reports/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SingleUserReportsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='calendar'
          element={
            <>
              <DocumentTitle
                title={
                  process.env.REACT_APP_NAME +
                  ' | ' +
                  intl.formatMessage({ id: 'REPORTS.CALENDARVIEW' })
                }
              ></DocumentTitle>
              <PageTitle breadcrumbs={reportsBreadcrumbs}>
                {intl.formatMessage({ id: 'REPORTS.CALENDARVIEW' })}
              </PageTitle>
              <CalendarViewPage />
            </>
          }
        />
        <Route index element={<Navigate to='/reports/calendar' />} />
      </Route>
    </Routes>
  )
}

export default SingleUserReportsPage
