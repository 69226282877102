import { ReactNode, SyntheticEvent } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'
import Select from '../../utils/Form/Widgets/Select'
import Check from '../../utils/Form/Widgets/Check'
import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import Textarea from '../../utils/Form/Widgets/Textarea'
import { ManuelFileInputModal } from '../data/components/ManuelFileInputModal'
import FileInput from '../../utils/Form/Widgets/FileInput'
import MultipleFileInputWidget from '../../utils/Form/Widgets/MultipleFileInputWidget'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
  companyId?: string
}

class Task {
  company: number | null = null
  responsible: number | null = null
  subject: string = ''
  action: string = ''
  category: string = ''
  status: string = ''
  due_date: string = ''
}

class TaskEditModal extends FormHandler {
  statusOptions = [
    { value: 'in_progress', label: 'In Progress' },
    { value: 'pending', label: 'Pending' },
    { value: 'completed', label: 'Completed' },
    { value: 'closed', label: 'Closed' },
    { value: 'outstanding', label: 'Outstanding' },
  ]
  companyOptions: any[] = []
  usersOptions: any[] = []
  categoryOptions: any[] = []
  subjectOptions: any[] = []

  constructor(props: Props) {
    super(props)
    const initialEntity = new Task()
    if (props.itemIdForUpdate === 'add' && props.companyId) {
      initialEntity.company = Number(props.companyId)
    }
    this.state = { entity: initialEntity }
  }

  async componentDidMount(): Promise<void> {
    try {
      await super.componentDidMount()
      await this.loadSelectOptions()
    } catch (error) {
      console.error('Error in componentDidMount:', error)
    }
  }

  async loadSelectOptions(): Promise<void> {
    try {
      const [companyResponse, userResponse, categoryResponse, subjectResponse] = await Promise.all([
        axios.get('/company/getCompanyOptions'),
        axios.get('/users/getUserOptions/all'),
        axios.get('/task_categories/getTaskCategoryOptions'),
        axios.get('/task_subjects/getTaskSubjectOptions'),
      ])
      this.companyOptions = companyResponse.data.data
      this.usersOptions = userResponse.data.data
      this.categoryOptions = categoryResponse.data.data
      this.subjectOptions = subjectResponse.data.data
      this.setState({ ...this.state })
    } catch (error) {
      console.error('Error loading select options:', error)
    }
  }

  getApiUrl(): string {
    return '/tasks/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/tasks/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      tasks: this.state.entity,
    }
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Task'
    let disabledCompany = false
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = 'Edit Task - Task Id:' + this.props.itemIdForUpdate
    }
    let defaultCompanyValue = state.entity?.company || 0
    if (this.props.itemIdForUpdate !== 'add') {
      title = 'Edit Task - Task Id:' + this.props.itemIdForUpdate
      disabledCompany = true
    } else if (this.props.companyId) {
      disabledCompany = true
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault()
            try {
              const response = await this.handleSubmit(event)
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
              this.props.onHide()
            } catch (error) {
              console.error('Error saving task:', error)
              Swal.fire({
                title: 'Error',
                text: 'An error occurred while saving the task. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Select
                title='Company'
                name='company'
                options={this.companyOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.company}
                required={true}
                disabled={disabledCompany}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Responsible'
                name='responsible'
                options={this.usersOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.responsible}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Subject'
                name='subject'
                options={
                  this.subjectOptions && this.subjectOptions.length > 0
                    ? this.subjectOptions
                    : [
                        {
                          value: 'NavigateToAddSubject',
                          label: 'Please Add Subject From Settings. Click for Settings!',
                        },
                      ]
                }
                onChange={(event: any) => {
                  if (event.target.value === 'NavigateToAddSubject') {
                    window.location.href = '/settings/task_subjects'
                  }
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.subject}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Category'
                name='category'
                options={
                  this.categoryOptions && this.categoryOptions.length > 0
                    ? this.categoryOptions
                    : [
                        {
                          value: '',
                          label: 'Please add category from settings. Click For Settings!',
                        },
                      ]
                }
                onChange={(event: any) => {
                  if (event.target.value === '') {
                    window.location.href = '/settings/task_categories'
                  }
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.category}
                required={true}
              />
            </FormGroup>

            <FormGroup>
              <Textarea
                title='Action'
                name='action'
                defaultValue={state.entity.action}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                className='form-control'
                required={true}
              />
            </FormGroup>

            <FormGroup>
              <Field
                title='Due Date'
                name='due_date'
                type='date'
                defaultValue={state.entity.due_date}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                className='form-control'
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Status'
                name='status'
                options={this.statusOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.status}
                required={true}
              />
            </FormGroup>
            <MultipleFileInputWidget
              fileUploadKeyUrl='/tasks/getTaskAttachmentUploadKey'
              name='task_attachments'
              fieldName='attachment'
              onChange={(event) => {
                this.handleInputChange(event)
              }}
              accept='*'
              defaultFiles={state.entity.task_attachments} //data_request_files (reference id) data_files (file id)
            />
            <FormGroup />
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { TaskEditModal }
