import { Component, SyntheticEvent } from 'react'
import axios from 'axios'
import { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'

export type FormParams = {
  itemIdForUpdate?: string
  entity: object
}
abstract class FormHandler extends Component<any> {
  public state: FormParams
  constructor(props: any) {
    super(props)
    this.state = { entity: {} }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.itemIdForUpdate !== this.props.itemIdForUpdate) {
      this.getObjectData()
    }
  }

  getObjectData(): void {
    let singleUrl = this.getSingleUrl()
    if (singleUrl) {
      axios.get(singleUrl).then((response) => {
        this.setState({
          entity: response.data.data,
        })
      })
    }
  }

  abstract getApiUrl(): string
  abstract getSingleUrl(): string | null
  abstract getPostData(): object

  componentDidMount(): void {
    this.getObjectData()
  }

  handleInputChange(event: SyntheticEvent) {
    const target = event.target as any
    const value = target.type === 'checkbox' ? target.checked : target.value
    const split = target.name.split('.')
    if (split.length <= 1) {
      this.setState({
        entity: {
          ...this.state.entity,
          [target.name]: value,
        },
      })
    } else {
      let entity = this.state.entity as any
      if (!entity[split[0]]) {
        entity[split[0]] = {}
      }
      if (!entity[split[0]][split[1]]) {
        entity[split[0]][split[1]] = {}
      }
      entity[split[0]][split[1]][split[2]] = value
      this.setState({
        entity: entity,
      })
    }
  }

  handleSubmit(event: SyntheticEvent): Promise<AxiosResponse> {
    event.preventDefault()
    return axios.post(this.getApiUrl(), {
      ...this.getPostData(),
    })
  }

  async handleDelete(event: SyntheticEvent): Promise<AxiosResponse> {
    event.preventDefault()
    let result = await Swal.fire({
      title: 'Warning',
      html: 'Are you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary',
      },
    })
    if (result.isConfirmed) {
      return axios.delete(this.getApiUrl())
    } else {
      return new Promise(() => {})
    }
  }
}

export default FormHandler
