import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import '../rank.css'
import SiteChartModal from './SiteChartModal'
import * as XLSX from 'xlsx'

interface BenchmarkTableProps {
  parameter: {
    name: string
    unit: string
  }
  data?: {
    name: string
    feature_value: string
    electricity_consumption: string
    gas_consumption: string
    total_combined_consumption: string
    consumption_per_feature: string
    average_per_feature: string
  }[]
  company: string
  start_date: string
  end_date: string
}

const BenchmarkTable: React.FC<BenchmarkTableProps> = ({
  parameter,
  data,
  company,
  start_date,
  end_date,
}) => {
  const [sortField, setSortField] = useState<string>('name')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [rankedData, setRankedData] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSite, setSelectedSite] = useState<any>('')
  const [selectedSiteName, setSelectedSiteName] = useState<any>('')
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set())

  const handleSort = (field: string) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortDirection(direction)
  }

  const rankData = (sortedData: any[]) => {
    const ranked = sortedData.map((item, index) => ({
      ...item,
      rank: index + 1,
    }))
    setRankedData(ranked)
  }

  useEffect(() => {
    if (data) {
      const sortedData = data
        ? [...data]
            .sort((a, b) => {
              const aValue = parseFloat(a.consumption_per_feature)
              const bValue = parseFloat(b.consumption_per_feature)
              return sortDirection === 'asc' ? aValue - bValue : bValue - aValue
            })
            .map((item, index) => ({ ...item, rank: index + 1 }))
        : []

      rankData(sortedData)
    }
  }, [data])

  const sortedData = rankedData
    ? [...rankedData].sort((a, b) => {
        const aValue = a[sortField as keyof typeof a]
        const bValue = b[sortField as keyof typeof b]

        if (typeof aValue === 'string') {
          return sortDirection === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        } else {
          return sortDirection === 'asc'
            ? parseFloat(aValue) - parseFloat(bValue)
            : parseFloat(bValue) - parseFloat(aValue)
        }
      })
    : []

  const renderSortIcon = (field: string) => {
    if (sortField === field) {
      return sortDirection === 'asc' ? (
        <KTSVG
          path='/media/icons/duotune/arrows/arr066.svg'
          className='svg-icon svg-icon-1 svg-icon-primary ms-2'
        />
      ) : (
        <KTSVG
          path='/media/icons/duotune/arrows/arr065.svg'
          className='svg-icon svg-icon-1 svg-icon-primary ms-2'
        />
      )
    }
    return (
      <KTSVG
        path='/media/icons/duotune/arrows/arr065.svg'
        className='svg-icon svg-icon-1 svg-icon-gray-500 ms-2'
      />
    )
  }

  const openModal = (siteData: any) => {
    setSelectedSite(siteData.site_id ?? '')
    setSelectedSiteName(siteData.name ?? '')
  }

  useEffect(() => {
    if (selectedSite) {
      setIsModalOpen(true)
    }
  }, [selectedSite])

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedSite(null)
  }

  const exportToExcel = () => {
    if (!data || data.length === 0) return

    const dataToExport =
      selectedRows.size > 0 ? sortedData.filter((item) => selectedRows.has(item.rank)) : sortedData

    const excelData = dataToExport.map((item) => {
      const row: Record<string, string | number> = {
        Rank: item.rank,
        Site: item.name,
        'Total Electric Consumption (kWh)': parseFloat(item.electricity_consumption).toFixed(2),
        'Total Gas Consumption (kWh)': parseFloat(item.gas_consumption).toFixed(2),
        'Total Combined Consumption (kWh)': parseFloat(item.total_combined_consumption).toFixed(2),
        [`Average Consumption Per ${parameter.unit} (kWh)`]: parseFloat(
          item.average_per_feature
        ).toFixed(2),
        [`Consumption Per ${parameter.unit} (kWh)`]: parseFloat(
          item.consumption_per_feature
        ).toFixed(2),
      }
      row[`${parameter.name} (${parameter.unit})`] = parseFloat(item.feature_value).toFixed(2)
      return row
    })

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(excelData)
    XLSX.utils.book_append_sheet(wb, ws, 'Benchmark Data')
    XLSX.writeFile(wb, `benchmark_data_${new Date().toISOString().split('T')[0]}.xlsx`)
  }

  const handleRowSelect = (rank: number) => {
    setSelectedRows((prev) => {
      const newSelection = new Set(prev)
      if (newSelection.has(rank)) {
        newSelection.delete(rank)
      } else {
        newSelection.add(rank)
      }
      return newSelection
    })
  }

  const handleSelectAll = () => {
    if (selectedRows.size === sortedData.length) {
      setSelectedRows(new Set())
    } else {
      setSelectedRows(new Set(sortedData.map((item) => item.rank)))
    }
  }

  const handleClearSelection = () => {
    setSelectedRows(new Set())
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <button className='btn btn-sm btn-primary me-2' onClick={exportToExcel}>
              <i className='fas fa-file-excel me-2'></i>
              Export to Excel
            </button>
            <div className='d-flex align-items-center'>
              {selectedRows.size > 0 ? (
                <>
                  <button className='btn btn-sm btn-danger me-2' onClick={handleClearSelection}>
                    <i className='fas fa-times me-2'></i>
                    Clear Selection
                  </button>
                  <span className='text-muted me-2'>
                    {selectedRows.size} row{selectedRows.size !== 1 ? 's' : ''} selected
                  </span>
                </>
              ) : (
                <button className='btn btn-sm btn-primary' onClick={handleSelectAll}>
                  <i className='fas fa-check-double me-2'></i>
                  Select All
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {data && data.length > 0 ? (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'>
                    <input
                      type='checkbox'
                      className='form-check-input form-check-input-solid'
                      checked={selectedRows.size === sortedData.length}
                      onChange={handleSelectAll}
                      style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                    />
                  </th>
                  <th className='min-w-50px' onClick={() => handleSort('rank')}>
                    Rank
                    {renderSortIcon('rank')}
                  </th>
                  <th className='min-w-150px' onClick={() => handleSort('name')}>
                    Site
                    {renderSortIcon('name')}
                  </th>
                  <th className='min-w-140px' onClick={() => handleSort('feature_value')}>
                    {parameter.name} {' ('} {parameter.unit} {')'}
                    {renderSortIcon('feature_value')}
                  </th>
                  <th className='min-w-120px' onClick={() => handleSort('electricity_consumption')}>
                    Total Electric Consumption (kWh)
                    {renderSortIcon('electricity_consumption')}
                  </th>
                  <th className='min-w-120px' onClick={() => handleSort('gas_consumption')}>
                    Total Gas Consumption (kWh)
                    {renderSortIcon('gas_consumption')}
                  </th>
                  <th
                    className='min-w-120px'
                    onClick={() => handleSort('total_combined_consumption')}
                  >
                    Total Combined Consumption (kWh)
                    {renderSortIcon('total_combined_consumption')}
                  </th>
                  <th className='min-w-120px'>
                    Average Consumption Per {parameter.unit} (kWh)
                    {renderSortIcon('average_per_feature')}
                  </th>
                  <th className='min-w-120px' onClick={() => handleSort('consumption_per_feature')}>
                    Consumption Per {parameter.unit} (kWh)
                    {renderSortIcon('consumption_per_feature')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => (
                  <tr
                    key={index}
                    className={`${item.rank <= 3 ? `rank-${item.rank}` : ''} ${
                      selectedRows.has(item.rank) ? 'table-active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRowSelect(item.rank)}
                  >
                    <td>
                      <input
                        type='checkbox'
                        className='form-check-input form-check-input-solid'
                        checked={selectedRows.has(item.rank)}
                        onChange={(e) => {
                          e.stopPropagation()
                          handleRowSelect(item.rank)
                        }}
                        style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                      />
                    </td>
                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{item.rank}</td>
                    <td>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.stopPropagation()
                          openModal(item)
                        }}
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        {item.name}
                      </a>
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.feature_value).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.electricity_consumption).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.gas_consumption).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.total_combined_consumption).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.average_per_feature).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.consumption_per_feature).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className='text-muted'>No data available.</p>
          )}
        </div>
      </div>

      {isModalOpen && (
        <SiteChartModal
          benchmarkData={{
            siteName: selectedSiteName,
            companyValue: company,
            siteValue: selectedSite,
            start_date: start_date,
            end_date: end_date,
          }}
          show={true}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

export default BenchmarkTable
