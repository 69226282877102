import React, { FC, SyntheticEvent, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { SiteEditModal } from '../SiteEditModal'
import { useIntl } from 'react-intl'
import CompanySitesTable from './table/CompanySitesTable'

interface SitesListProps {
  companyId?: any
  location?: any
}

const SitesList: FC<SitesListProps> = ({ companyId, location }) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const intl = useIntl()

  return (
    <>
      <CompanySitesTable
        title={intl.formatMessage({ id: 'MENU.COMPANYSITES' })}
        dataUrl={`/company/sites/list${companyId ? `?companyId=${companyId}` : ''}`}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/company/sites/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you want to delete this site?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('company/sites/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh()
                  })
              }
            })
          })
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'COMPANY.ADDNEWSITE' }),
            class: 'btn btn-sm btn-primary',
            icon: 'fas fa-bullseye',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate('add')
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        //@ts-ignore
        location={location}
      />
      {itemIdForUpdate !== '' ? (
        <SiteEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          companyId={companyId}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export { SitesList }
