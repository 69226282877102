import React, { useState, useEffect } from 'react'

import Chart from 'react-apexcharts'
interface TimeSeriesChartProps {
  data: {
    x: string
    y: number
  }[]
  title: string
  axisTitle?: string
  ordinatTitle?: string
  height?: number
  theme?: string
}

const TimeSeriesChart = ({
  data,
  title,
  axisTitle,
  ordinatTitle,
  height,
  theme,
}: TimeSeriesChartProps) => {
  const [series] = useState([
    {
      name: title,
      data: data,
    },
  ])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area' as const,
      height: height ?? 350,
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.1,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM yyyy',
        },
      },
      title: {
        text: axisTitle,
      },
    },
    yaxis: {
      title: {
        text: ordinatTitle,
      },
    },
    theme: {
      mode: 'light',
      palette: theme ?? 'palette1',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
    },
  }

  return (
    <div className='w-full p-4 bg-white rounded-lg shadow-lg'>
      <Chart options={options} series={series} type='area' height={350} />
    </div>
  )
}

export default TimeSeriesChart
