import React from 'react'
import { Modal, FormGroup } from 'react-bootstrap'
import Field from '../../../../utils/Form/Widgets/Field'
import FormHandler from '../../../../utils/Form/FormHandler'
import { Props } from 'react-select'
import AutocompleteSelect from '../../../../utils/Form/Widgets/AutocompleteSelect'

class EmailImportConfiguration {
  subject?: string
  from?: string
  file_structure?: number = 0
}

class EmailImportConfigurationFormModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new EmailImportConfiguration() }
  }

  getApiUrl(): string {
    return '/email_import_configuration/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/email_import_configuration/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      email_import_configuration: this.state.entity,
    }
  }
  public render(): React.ReactNode {
    var state = this.state as any
    var title =
      this.props.itemIdForUpdate === 'add'
        ? 'New Email Import Configuration'
        : 'Edit: ' + state.entity.subject
    return (
      <Modal
        id='email-import-configuration-form-modal'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered shadow-lg'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormGroup>
                <Field
                  title='Subject'
                  className='form-control'
                  type='text'
                  name='subject'
                  onInput={(event: any) => {
                    this.handleInputChange(event)
                  }}
                  placeholder='Subject'
                  required={true}
                  value={state.entity.subject}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  title='From'
                  className='form-control'
                  type='email'
                  name='from'
                  onInput={(event: any) => {
                    this.handleInputChange(event)
                  }}
                  placeholder='test@energyimpact.com'
                  required={true}
                  value={state.entity.from}
                />
              </FormGroup>
              <FormGroup>
                <AutocompleteSelect
                  title='File Structure'
                  name='file_structure'
                  autoCompleteTokenUrl='/import_file_structure_template/autocomplete'
                  onChange={(event: any) => {
                    this.handleInputChange(event)
                  }}
                  defaultValue={state.entity.file_structure}
                />
              </FormGroup>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save Configuration
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default EmailImportConfigurationFormModal
