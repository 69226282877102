import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import Field from '../../../utils/Form/Widgets/Field'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}
class TaskCategory {
  name?: string
}
class TaskCategoriesEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new TaskCategory() }
  }

  getApiUrl(): string {
    return '/task_categories/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/task_categories/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      task_categories: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    return (
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === 'add' ? 'Add' : 'Edit'} Task Category
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Name'
                className='form-control'
                type='text'
                name='name'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.name}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              <span className='fa fa-floppy-save'></span>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { TaskCategoriesEditModal }
