import axios from 'axios'
import Swal from 'sweetalert2'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE

// Language set to en always.
axios.interceptors.request.use((config) => {
  config.params = { ...config.params, lang: 'en' }
  return config
})

// Error message handling.
axios.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 &&
      response.data.messages?.[3] &&
      !response.config.url?.includes('user_settings/save')
    ) {
      Swal.fire({
        title: 'Success',
        text: response.data.messages[3].join('<br/>'),
        icon: 'success',
        toast: true,
        timer: 2000,
        timerProgressBar: true,
        position: 'bottom-start',
        showConfirmButton: false,
        customClass: {
          popup: 'bg-light-info',
        },
      })
    }
    return response
  },
  (error) => {
    if (error.response.status === 400) {
      Swal.fire({
        title: 'Error',
        text: error.response.data.messages.map((t: any) => Object.values(t).join('\n')),
        icon: 'error',
      })
    }
    return error
  }
)
