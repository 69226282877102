import axios from 'axios'
import { ResultProps } from '../../utils/ResultViewer/ResultViewer'
import Table from '../../utils/ResultViewer/Table'
type CompanyTasksProps = ResultProps & {
  commentCallback: Function
  attachmentCallback: Function
} & {
  commentsCount: any
}
class CompanyTasksTable extends Table {
  //@ts-ignore
  public state: CompanyTasksProps
  constructor(props: CompanyTasksProps) {
    super(props)
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      commentCallback: props.commentCallback,
      attachmentCallback: props.attachmentCallback,
      commentsCount: props.commentsCount,
    }
  }
  componentDidMount(): void {
    axios.get('/tasks/getTaskCommentCount').then((response) => {
      this.setState({
        ...this.state,
        commentsCount: response.data.data,
      })
    })
    this.fetchData()
  }
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'status') {
      let badgeClass = 'badge-success'
      let statusLabel = row[key]
      switch (row[key]) {
        case 'in_progress':
          badgeClass = 'badge-primary'
          statusLabel = 'In Progress'
          break
        case 'pending':
          badgeClass = 'badge-warning'
          statusLabel = 'Pending'
          break
        case 'completed':
          badgeClass = 'badge-success'
          statusLabel = 'Completed'
          break
        case 'closed':
          badgeClass = 'badge-dark'
          statusLabel = 'Closed'
          break
        case 'outstanding':
          badgeClass = 'badge-danger'
          statusLabel = 'Outstanding'
          break
      }
      return (
        <td key={colIndex}>
          <div className={'badge ' + badgeClass}>{statusLabel}</div>
        </td>
      )
    } else if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <div className='d-flex justify-content flex-shrink-0'>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-50'
            >
              TASK_{row[key]}
            </button>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fa fa-edit'></span>
            </button>
            <button
              onClick={() => this.props.deleteCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            >
              <span className='fa fa-trash'></span>
            </button>
            <button
              onClick={() => this.state.commentCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1 position-relative'
            >
              {this.state.commentsCount &&
                this.state.commentsCount.map(
                  (item: any) =>
                    item.task === row[key] && (
                      <span
                        key={item.task}
                        className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'
                        style={{ fontSize: '0.8em' }}
                      >
                        {item.comment_count}
                      </span>
                    )
                )}
              <span className='fa fa-comment'></span>
            </button>
            {/*
            <button
              onClick={() => this.state.attachmentCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
            >
              <span className='fa fa-paperclip'></span>
            </button>
            */}
          </div>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default CompanyTasksTable
