import React, { useEffect, useState } from 'react'
import { ChartsWidget1, TablesWidget1 } from '../../../_metronic/partials/widgets'
import TimeSeriesChart from './TimeSeriesChart'
import axios, { AxiosResponse } from 'axios'
import { WithData } from '../../utils/axiosWithCSRF'
import HourlyTimeSeriesChart from './HourlyTimeSeriesChart'

function PredictionPage() {
  const [annualDailyElectricityData, setAnnualDailyElectricityData] = useState<any>(null)
  const [annualDailyGasData, setAnnualDailyGasData] = useState<any>(null)
  const [annualHourlyDataElectricity, setAnnualHourlyDataElectricity] = useState<any>(null)
  const [annualHourlyDataGas, setAnnualHourlyDataGas] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const startDate = new Date('2025-11-27')
  const endDate = new Date('2026-11-26')
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startDate)
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(
    new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000)
  )

  const [summarizeData, setSummarizeData] = useState<any>(null)

  const fetchAnnualDailyElectrictyData = async () => {
    try {
      const response: AxiosResponse<WithData<any>> = await axios.get(
        '/analytics/getAnnualDataDailyElectricity'
      )
      setAnnualDailyElectricityData(response.data.data)
    } catch (error) {
      console.error('Failed to fetch electricity data:', error)
      setAnnualDailyElectricityData([])
    }
  }

  const fetchAnnualDailyGasData = async () => {
    try {
      const response: AxiosResponse<WithData<any>> = await axios.get(
        '/analytics/getAnnualDataDailyGas'
      )
      setAnnualDailyGasData(response.data.data)
    } catch (error) {
      console.error('Failed to fetch gas data:', error)
      setAnnualDailyGasData([])
    }
  }

  const fetchHourlyData = async (
    startDate: string,
    endDate: string,
    meterType: 'Electricity' | 'Gas'
  ) => {
    setIsLoading(true)
    try {
      const response: AxiosResponse<WithData<any>> = await axios.get(
        '/analytics/getAnnualDataHourly',
        {
          params: {
            meterType,
            startDate,
            endDate,
          },
        }
      )

      if (meterType === 'Gas') {
        setAnnualHourlyDataGas(response.data.data)
      } else {
        setAnnualHourlyDataElectricity(response.data.data)
      }
    } catch (error) {
      console.error('Failed to fetch hourly data:', error)
      if (meterType === 'Gas') {
        setAnnualHourlyDataGas([])
      } else {
        setAnnualHourlyDataElectricity([])
      }
    } finally {
      setIsLoading(false)
    }
  }

  const fetchSummaryData = async () => {
    try {
      const response: AxiosResponse<WithData<any>> = await axios.get('/analytics/getSummaryData')
      setSummarizeData(response.data.data)
    } catch (error) {
      console.error('Failed to fetch summary data:', error)
    }
  }

  useEffect(() => {
    fetchAnnualDailyElectrictyData()
    fetchAnnualDailyGasData()
    fetchSummaryData()
  }, [])

  // Seçili tarih değiştiğinde saatlik verileri güncelle
  useEffect(() => {
    const formattedStartDate = selectedStartDate.toISOString().split('T')[0]
    const formattedEndDate = selectedEndDate.toISOString().split('T')[0]

    fetchHourlyData(formattedStartDate, formattedEndDate, 'Electricity')
    fetchHourlyData(formattedStartDate, formattedEndDate, 'Gas')
  }, [selectedStartDate, selectedEndDate])

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = new Date(e.target.value)
    const newEndDate = new Date(newStartDate)
    newEndDate.setDate(newEndDate.getDate() + 7)

    if (newStartDate >= startDate && newEndDate <= endDate) {
      setSelectedStartDate(newStartDate)
      setSelectedEndDate(newEndDate)
    } else {
      alert('Selected date must be between 27-11-2025 and 26-11-2026')
    }
  }

  const handlePreviousWeek = () => {
    const newStartDate = new Date(selectedStartDate)
    newStartDate.setDate(newStartDate.getDate() - 7)
    const newEndDate = new Date(selectedEndDate)
    newEndDate.setDate(newEndDate.getDate() - 7)

    if (newStartDate >= startDate) {
      setSelectedStartDate(newStartDate)
      setSelectedEndDate(newEndDate)
    } else {
      alert('Cannot go before start date (27-11-2025)')
    }
  }

  const handleNextWeek = () => {
    const newStartDate = new Date(selectedStartDate)
    newStartDate.setDate(newStartDate.getDate() + 7)
    const newEndDate = new Date(selectedEndDate)
    newEndDate.setDate(newEndDate.getDate() + 7)

    if (newEndDate <= endDate) {
      setSelectedStartDate(newStartDate)
      setSelectedEndDate(newEndDate)
    } else {
      alert('Cannot go after end date (26-11-2026)')
    }
  }

  return (
    <div className='row gy-10 gx-xl-10'>
      {annualDailyElectricityData && (
        <div className='col-xl-12'>
          <TimeSeriesChart
            data={annualDailyElectricityData}
            title={'Daily Total Energy Consumption (Electricity)'}
            axisTitle={'Date'}
            ordinatTitle={'Energy Consumption (kWh)'}
            theme='palette11'
          />
        </div>
      )}
      {annualDailyGasData && (
        <div className='col-xl-12'>
          <TimeSeriesChart
            data={annualDailyGasData}
            title={'Daily Total Energy Consumption (Gas)'}
            axisTitle={'Date'}
            ordinatTitle={'Energy Consumption (kWh)'}
            theme='palette8'
          />
        </div>
      )}
      <div className='col-xl-12'>
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <div className='btn-group'>
            <button
              className='btn btn-sm btn-light-primary'
              onClick={handlePreviousWeek}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Previous Week'}
            </button>
            <button
              className='btn btn-sm btn-light-primary'
              onClick={handleNextWeek}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Next Week'}
            </button>
          </div>
          <div className='d-flex align-items-center gap-3'>
            <input
              type='date'
              className='form-control form-control-sm'
              value={selectedStartDate.toISOString().split('T')[0]}
              onChange={handleDateChange}
              min={startDate.toISOString().split('T')[0]}
              max={endDate.toISOString().split('T')[0]}
              disabled={isLoading}
            />
          </div>
        </div>
        {isLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '400px' }}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          annualHourlyDataElectricity && (
            <HourlyTimeSeriesChart
              data={annualHourlyDataElectricity}
              title={'Hourly Energy Consumption(Electricity)'}
              axisTitle={'Date'}
              ordinatTitle={'Energy Consumption (kWh)'}
              theme='palette3'
              height={400}
            />
          )
        )}
      </div>

      <div className='col-xl-12'>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <div className='card card-xl-stretch mb-xl-8'>
              <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>
                  <i className='fas fa-bolt text-warning me-2'></i>
                  Total Electricity Consumption
                </h3>
              </div>
              <div className='card-body pt-0'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='d-flex align-items-center flex-grow-1'>
                    <div className='d-flex flex-column'>
                      <span className='fs-2x fw-bold text-primary'>
                        {summarizeData?.total_electric_consumption?.toFixed(2)}
                        <span className='fs-4 ms-2 text-gray-600'>kWh</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='card card-xl-stretch mb-xl-8'>
              <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>
                  <i className='fas fa-fire text-danger me-2'></i>
                  Total Gas Consumption
                </h3>
              </div>
              <div className='card-body pt-0'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='d-flex align-items-center flex-grow-1'>
                    <div className='d-flex flex-column'>
                      <span className='fs-2x fw-bold text-primary'>
                        {summarizeData?.total_gas_consumption?.toFixed(2)}
                        <span className='fs-4 ms-2 text-gray-600'>kWh</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className='col-xl-12'>
        {summarizeData && summarizeData.gas_consumption_by_month && (
          <div className='col-xl-12'>
            <TimeSeriesChart
              data={summarizeData.gas_consumption_by_month}
              title={'Monthly Energy Consumption'}
              axisTitle={'Month'}
              ordinatTitle={'Energy Consumption (kWh)'}
              theme='palette1'
            />
          </div>
        )}{' '}
      </div>
        */}
    </div>
  )
}

export default PredictionPage
