import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
interface HourlyTimeSeriesChartProps {
  data: {
    x: number
    y: number
  }[]
  title?: string
  axisTitle?: string
  ordinatTitle?: string
  height?: number
  theme?: string
}
const HourlyTimeSeriesChart = ({
  data,
  title,
  axisTitle,
  ordinatTitle,
  height,
  theme,
}: HourlyTimeSeriesChartProps) => {
  const [series] = useState([
    {
      name: title,
      data: data.length > 0 ? data : [{ x: 0, y: 0 }],
    },
  ])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      height: height ?? 350,
      zoom: {
        enabled: true,
        type: 'x',
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.1,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM yyyy',
          day: 'dd MMM',
          hour: 'HH:mm',
        },
        format: 'dd MMM HH:mm',
      },
      title: {
        text: axisTitle,
      },
      tickAmount: 6,
    },
    yaxis: {
      title: {
        text: ordinatTitle,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2)
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy HH:mm',
      },
      y: {
        formatter: function (value) {
          return value.toFixed(2)
        },
      },
    },
    theme: {
      mode: 'light',
      palette: theme ?? 'palette1',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0, 100],
      },
    },
  }

  return (
    <div className='w-full p-4 bg-white rounded-lg shadow-lg'>
      <Chart options={options} series={series} type='area' height={350} />
    </div>
  )
}

export default HourlyTimeSeriesChart
