import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import FormHandler, { FormParams } from '../../utils/Form/FormHandler'
import Textarea from '../../utils/Form/Widgets/Textarea'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  taskId?: string
  userId?: string
  refreshFunction?: Function
}

class Comment {
  task?: string
  user?: string
  body?: string
}

class CommentEditModal extends FormHandler {
  public state: FormParams
  constructor(props: Props) {
    super(props)
    this.state = {
      entity: new Comment(),
    }
  }

  getApiUrl(): string {
    return '/task_comments/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/task_comments/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      task_comments: {
        ...this.state.entity,
        task: this.props.taskId,
        user: this.props.userId,
      },
    }
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Task Comment'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = 'Edit Task Comment - Task Id:' + entity.task
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
        style={{ zIndex: 1065 }}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault()
            try {
              const response = await this.handleSubmit(event)
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
              this.props.onHide()
            } catch (error) {
              console.error('Error saving site:', error)
              Swal.fire({
                title: 'Error',
                text: 'An error occurred while saving the site. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Textarea
                title='Comment'
                className='form-control'
                type='text'
                name='body'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.body}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { CommentEditModal }
