import { ResultProps } from '../../../../utils/ResultViewer/ResultViewer'
import Table from '../../../../utils/ResultViewer/Table'
type CompanyMetersProps = ResultProps & {
  location?: any
}
class CompanyMetersTable extends Table {
  //@ts-ignore
  public state: CompanyMetersProps
  constructor(props: CompanyMetersProps) {
    super(props)
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      location: props.location,
    }
  }
  renderHeader(key: string): JSX.Element {
    if (this.state.location === 'overview' && key === 'company') {
      return <th className='d-none'>{key}</th>
    }
    return super.renderHeader(key)
  }
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (this.state.location === 'overview' && key === 'company') {
      return <td className='d-none'>{row[key]}</td>
    } else if (key === 'logo') {
      let imageUrl = row[key] ? row[key] : '/public/media/avatars/blank.png'
      return (
        <td key={colIndex}>
          <div className='symbol symbol-45px me-5'>
            <img src={imageUrl} alt={row['name']} />
          </div>
        </td>
      )
    } else if (key === 'is_active' || key === 'status') {
      let badgeClass = 'badge-success'
      switch (row[key]) {
        case 'Active':
          badgeClass = 'badge-success'
          break
        case 'Passive':
          badgeClass = 'badge-warning'
          break
        case 'Disabled':
          badgeClass = 'badge-warning'
          break
      }
      return (
        <td key={colIndex}>
          <div className={'badge ' + badgeClass}>{row[key]}</div>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default CompanyMetersTable
