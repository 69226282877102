/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Company } from './CompanyHeader'

interface CompanyDetailsProps {
  company: Company
}

export function CompanyDetails({ company }: CompanyDetailsProps) {
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Name</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{company?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Number of Employees</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{company?.number_of_empleyees}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Status</label>
            <div className='col-lg-8'>
              <span
                className={`badge ${
                  company?.status === 'active' ? 'badge-success' : 'badge-danger'
                }`}
              >
                {company?.status === 'active' ? 'Active' : 'Passive'}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Is Reseller</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {company?.is_reseller ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Fiscal Day</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{company?.fiscal_day}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Fiscal Month</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{company?.fiscal_month}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Sole Trader</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {company?.sole_trader ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Target Consumption Ratio</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {company?.target_consumption_ratio} %
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Addresses</label>
            <div className='col-lg-8'>
              {company?.company_addresses.map((address, index) => (
                <div key={index} className='mb-3'>
                  <span className='fw-bolder fs-6 text-dark'>{address.address}</span>
                  <div className='text-muted'>
                    {address.city}, {address.county}, {address.country}
                  </div>
                  <div className='text-muted'>{address.phone}</div>
                  <div className='text-muted'>{address.email}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
