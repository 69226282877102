import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Table from '../../../../utils/ResultViewer/Table'
import { useIntl } from 'react-intl'
import EmailImportConfigurationFormModal from './EmailImportConfigurationFormModal'
import axios from 'axios'
import Swal from 'sweetalert2'

type EmailImportConfigurationModalProps = {
  show: boolean
  onHide: () => void
}

const EmailImportConfigurationsModal: React.FC<EmailImportConfigurationModalProps> = ({
  show,
  onHide,
}) => {
  const intl = useIntl()
  const [showForm, setShowForm] = useState(false)
  const [refreshFunction, setRefreshFunction] = useState<any>(null)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>(null)
  return (
    <Modal
      id='email-import-configuration-modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='xl'
      show={show}
      backdrop={true}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'Email Import Configurations' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          title={intl.formatMessage({ id: 'Email Import Configurations' })}
          dataUrl='/email_import_configuration/list'
          actionItems={[
            {
              text: intl.formatMessage({ id: 'Add new import rule' }),
              class: 'btn btn-sm btn-primary',
              icon: 'fa fa-plus',
              onClick: function (event: SyntheticEvent, refresh: Function) {
                setShowForm(true)
                setItemIdForUpdate('add')
                setRefreshFunction(() => refresh)
              },
            },
          ]}
          editCallback={(id: any, refresh: Function) => {
            setRefreshFunction(() => refresh)
            setShowForm(true)
            setItemIdForUpdate(id)
          }}
          deleteCallback={(id: any, refresh: Function) => {
            Swal.fire({
              title: 'Warning',
              html: 'Are you sure you want to delete this configuration?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios.delete('/email_import_configuration/save/' + id).then(() => {
                  refresh()
                })
              }
            })
          }}
        ></Table>
        {itemIdForUpdate !== null ? (
          <EmailImportConfigurationFormModal
            show={showForm}
            itemIdForUpdate={itemIdForUpdate}
            onHide={() => {
              setShowForm(false)
              setItemIdForUpdate(null)
            }}
            refreshFunction={() => {
              setShowForm(false)
              setItemIdForUpdate(null)
              refreshFunction()
            }}
          />
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default EmailImportConfigurationsModal
