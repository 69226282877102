import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import MultipleFileInputWidget from '../../utils/Form/Widgets/MultipleFileInputWidget'

type Props = {
  onHide: () => void
  show: boolean
  refreshFunction?: Function
}

class TaskAttachmentUploadModal extends FormHandler {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props)
  }

  getApiUrl(): string {
    return '/tasks/manuelInput'
  }
  getSingleUrl(): string | null {
    return null
  }

  getPostData(): object {
    return this.state.entity
  }
  render(): ReactNode {
    let title = 'Manuel File Input'
    return (
      <Modal
        id='task_attachment_input_modal'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction()
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='fw-bolder fs-5'>Select csv files to upload and save</p>
            <FormGroup>
              <MultipleFileInputWidget
                fileUploadKeyUrl='/tasks/getTaskAttachmentUploadKey'
                name='task_attachments'
                fieldName='attachment'
                onChange={(event) => {
                  this.handleInputChange(event)
                }}
                defaultFiles={[]}
                accept='*'
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            <button type='submit' className='btn btn-success btn-sm'>
              Save Files
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { TaskAttachmentUploadModal }
