import React, { FC, SyntheticEvent, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
import { TaskEditModal } from './TaskEditModal'
import CompanyTasksTable from './CompanyTasksTable'
import TaskCommentModal from './TaskCommentModal'
import { TaskAttachmentUploadModal } from './TaskAttachmentUploadModal'

const CompanyTasksList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [itemIdForComment, setItemIdForComment] = useState('')
  const [itemIdForAttachmentUpload, setItemIdForAttachmentUpload] = useState(false)
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const intl = useIntl()

  const setTaskAttachmentModal = (value: boolean) => {
    setItemIdForAttachmentUpload(value)
  }
  return (
    <>
      <CompanyTasksTable
        title={intl.formatMessage({ id: 'MENU.COMPANYTASKS' })}
        dataUrl='/tasks/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/tasks/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you want to delete this task?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('tasks/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh()
                  })
              }
            })
          })
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'COMPANY.ADDNEWTASK' }),
            class: 'btn btn-sm btn-primary',
            icon: 'fas fa-bullseye',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate('add')
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        //@ts-ignore
        commentCallback={(id: any, refresh: Function) => {
          setItemIdForComment(id)
          setRefreshFunction(() => refresh)
        }}
        //@ts-ignore
        attachmentCallback={(id: any, refresh: Function) => {
          setItemIdForAttachmentUpload(true)
          setRefreshFunction(() => refresh)
        }}
      />
      {itemIdForUpdate !== '' ? (
        <TaskEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
      {itemIdForComment && (
        <TaskCommentModal
          show={itemIdForComment !== ''}
          itemId={itemIdForComment}
          onHide={() => setItemIdForComment('')}
        />
      )}
      {itemIdForAttachmentUpload ? (
        <TaskAttachmentUploadModal
          show={itemIdForAttachmentUpload}
          onHide={() => {
            setTaskAttachmentModal(false)
          }}
          refreshFunction={() => {
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}
export default CompanyTasksList
