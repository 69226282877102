import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import axios from 'axios'
import Textarea from '../../../utils/Form/Widgets/Textarea'
import Select from '../../../utils/Form/Widgets/Select'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
  companyId?: string
}

class TaskComment {
  task?: string
  user?: string
  body?: string
}

class TaskCommentEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new TaskComment() }
  }
  taskOptions: any[] = []
  userOptions: any[] = []
  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.loadSelectOptions()

    if (this.props.itemIdForUpdate !== 'add') {
      const singleUrl = this.getSingleUrl()
      if (singleUrl) {
        try {
          const response = await axios.get(singleUrl)
          if (response.data.data) {
            this.setState({
              entity: response.data.data,
            })
          }
        } catch (error) {
          console.error('Error fetching task comment data:', error)
        }
      }
    }
  }

  async loadSelectOptions(): Promise<void> {
    try {
      const [taskResponse, userResponse] = await Promise.all([
        axios.get('/tasks/getTaskOptions/' + this.props.companyId),
        axios.get('/users/getUserOptions/all'),
      ])

      this.taskOptions = taskResponse.data.data
      this.userOptions = userResponse.data.data

      this.setState({ ...this.state })
    } catch (error) {
      console.error('Error loading select options:', error)
    }
  }
  getApiUrl(): string {
    return '/task_comments/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/task_comments/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      task_comments: {
        ...this.state.entity,
        user: this.props.userId,
      },
    }
  }

  handleTaskChange(event: any) {
    let entity = this.state.entity as any
    entity.task = event.target.value
    this.setState({ entity: entity })
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Task Comment'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = 'Edit Task Comment - Task Id:' + entity.task
    }

    return (
      <Modal
        id='kt_modal_create_task_comment'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Select
                title='Task'
                name='task'
                options={this.taskOptions}
                onChange={(event: any) => {
                  this.handleTaskChange(event)
                }}
                defaultValue={state.entity.task || ''}
                disabled={this.props.itemIdForUpdate !== 'add'}
              />
            </FormGroup>
            <FormGroup>
              <Textarea
                title='Comment'
                className='form-control'
                type='text'
                name='body'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.body}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { TaskCommentEditModal }
